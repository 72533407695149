.lbb-queue-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    overflow: hidden;

    /* padding: 10px; */
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;


    /* background-color: rgba(255, 255, 255, 0.23);
    backdrop-filter: saturate(180%) blur(20px); */

}

@media only screen and (max-width: 768px) {
    .lbb-queue-container {
        border: none;
    }
} 

.lbb-queue-inner {
    width: 100%;
    height: 100%;
    
    background-color: rgba(255, 255, 255, 0.23);
    backdrop-filter: saturate(180%) blur(20px);
}

@media only screen and (max-width: 995px) {
    .lbb-queue-inner {
       backdrop-filter: none;
    }
}

#queue .queue-header {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    height: 40%;
    margin: auto;
}

#queue .next-dancer {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    height: 45%;
    margin: auto;
}

#queue .queue-title {
    position: relative;
    margin: auto 0;
    text-transform: uppercase;
    color: var(--lbb-title-color);
    top: 50%;
    transform: translateY(-50%);
}

#queue .queue-title h2 {
    margin: 0;
    font-size: 42px;
    text-align: left;
    line-height: 1;
    /* color: white; */
}


@media only screen and (max-width: 480px) {
    #queue .queue-title h2 {
        font-size: 30px;
    }
}

#queue .avatar-wrapper {
    display: flex;
    position: relative;
    height: 55%;
    margin-left: 5px;
}

#queue .item {
    display: flex;
    justify-content: center;
    /* width: 20%;
    height: auto; */
    margin: 10px;
    --border-radius: 10px;
}
#queue ion-thumbnail {
    width: 85px;
    height: 85px;
    border-radius: 10px;
    background: var(--rocket-gradient);
    background: var(--lbb-main-color);
    color: white;
    --size: 85px!important;
}


@media only screen and (max-width: 675px) {
    #queue ion-thumbnail {
        width: 85px;
        height: 85px;
        --size: 85px!important;
    }
}

@media only screen and (max-width: 620px) {
    #queue ion-thumbnail {
        width: 65px;
        height: 65px;
        --size: 65px!important;
    }
}

@media only screen and (max-width: 480px) {
    #queue ion-thumbnail {
        width: 50px;
        height: 50px;
        --size: 50px!important;
    }
}



#queue ion-thumbnail ion-avatar {
    width: 100%;
    height: 100%;
}

#queue ion-thumbnail img {
    --border-radius: 10px;
}

#queue .ui-marquee {
    font-family: var(--lbb-font-family-standard);
    margin-top: -11px;
    padding-top: 12px;
    padding-bottom: 2px;
    
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--lbb-main-color);
}

#queue .arrow-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 60px;
    width: 210px;
    margin-top: 5px;
    align-items: center;
}

@media only screen and (max-width: 480px) {
    #queue .arrow-container {
        width: auto;
    }
}
#queue .arrow {
    width: 100px;
    height: 100px;
    margin-left: -50px;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="rgb(128,62,243)" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>');
    opacity: 0;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    animation-name: fadeInOpacity;
}

@media only screen and (max-width: 480px) {
    #queue .arrow {
        width: 70px;
        height: 70px;
        margin-left: -35px;
    }
}

#queue .first {
    animation-delay: 0.3s;
}

#queue .second {
    animation-delay: 0.6s;
}

.third {
    animation-delay: 0.9s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.lbb-queue-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


#queue .carousel {
    position: relative;
    height: 100%;
}

#queue .carousel__slider {
    position: relative;
    height: 60%;
    width: calc(100% - 64px);
    margin: auto;
}

#queue .carousel__slider-tray-wrapper {
    height: 100%;
    margin-top: -25px;
}

#queue .carousel__slider-tray {
    height: 100%;
}

#queue .carousel__inner-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}


#queue .carousel__slide {
    height: 100%;
}

.carousel__back-button,
.carousel__next-button {
    position: absolute;
    background: transparent;
    color: white;
    top: calc(100% - (55% * 0.5));
    transform:  translateY(calc(-100% - (55% * 0.5)));
    transition: all 0.3s ease;
    padding: 0;
}

.carousel__back-button ion-icon,
.carousel__next-button ion-icon {
    font-size: 2rem;
}

.carousel__back-button:disabled,
.carousel__next-button:disabled {
    opacity: 0.4;
}

.carousel__back-button:focus,
.carousel__next-button:focus {
    outline: none;
}

.carousel__back-button {
    left: 0;
}

.carousel__next-button {
    right: 0;
}
