.rating-container {
        /* position: absolute;
        height: auto;
        bottom: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 50;
        user-select: none; */

        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.33);
        backdrop-filter: saturate(180%) blur(20px);
        padding: 0 10px;
        border-radius: 10px;  
}


@media only screen and (max-width: 995px) {
    .rating-container {
       backdrop-filter: none;
    }
}

.rating-container h1 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
    color: white;
}

.champagne-icon {
    transform: rotate(45deg);
    width: 100%;
}

img.diamond-icon {
    height: 100%;
}

.rating {
    display: none;
}

.imagewrapper {
    display: inline-block;
    font-size: 2rem;
}

.imageWrapper.grayscale {
    filter:grayscale();
    opacity: 0.6;
}