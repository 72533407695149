.lbb-team {
    width: 100%;
    // height: 100%;
    display: flex;
    flex-direction: column;

    &.home {
        flex-direction: column-reverse;
    }

    .member-list {
        display: flex;
        --padding-start: 0;
        --inner-padding-end: 0;
        background: transparent;
        justify-content: space-around;

    }

    .team-member {
        flex: 1;
        display: flex;
        flex-direction: column;
        --padding-start: 0;
        --inner-padding-end: 0;
        --background: transparent;
        margin: 5px;
        max-width: 180px;
        // box-shadow: 0 0 8px #929292, 0 0 4px #929292, 0 0 7px #929292;
        box-shadow: var(--lbb-shadow);
    }

    .is-won {
        background-color: green;
    }

    ion-avatar {
        width: 100%;
        height: auto;
        margin-top: 0;
        margin-bottom: 0;
    }

    .avatar-label {
        display: flex;
        justify-content: center;
        color: white;
    }

    .team-name-wrapper {
        width: max-content;
        min-width: 40%;
        margin: 0 auto;

        h1 {
            margin: 0;
    
            padding-left: 40px;
            padding-right: 40px;
            color: white;
            // box-shadow: 0 0 8px #929292, 0 0 4px #929292, 0 0 7px #929292;
            box-shadow: var(--lbb-shadow);
        }
    }

}