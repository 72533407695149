.lbb-webrtc-queue-container {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255, 255, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-color: rgba(255, 255, 255, 0.33);
    backdrop-filter: saturate(180%) blur(20px);

}

@media only screen and (max-width: 995px) {
    .lbb-webrtc-queue-container {
       backdrop-filter: none;
    }
}

.lbb-webrtc-queue-container .live-badge {
    position: absolute;
    top: 5px;
    left: 5px;
    user-select: none;
    padding: 7px;
    font-family: var(--lbb-font-family-standard);
}

.lbb-webrtc-queue-container > div {
    min-width: 100%;

    /* object-fit: cover; */
    transform: translate(0, -50%);
    top: 50%;
    position: absolute;
    overflow: hidden;
    border-radius: 10px;
}

.lbb-webrtc-queue-container video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
       overflow: hidden

}

.lbb-webrtc-queue-container .video-placeholder {
    position: relative;
    width: 100%;
    height: 100%;
}

.lbb-webrtc-queue-container .video-placeholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lbb-webrtc-queue-container .video-placeholder .placeholder-text {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    text-transform: uppercase;
}