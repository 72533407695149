.profile-image-wrapper {
    width: 100%!important;
    height: auto!important;
}

.profile-image-wrapper ion-avatar {
    --border-radius: 10px;
}

.insta-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.insta-images {
    flex: 1 0 21%; 
    margin: 5px;
    height: auto;
}

.friends-with {
    padding-left: 16px;
}