.display-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 10px; 
    background-color: rgba(255, 255, 255, 0.33);
    backdrop-filter: saturate(180%) blur(20px);
}

@media only screen and (max-width: 995px) {
    .display-container {
       backdrop-filter: none;
    }
}

.display-container .live-badge {
    position: absolute;
    top: 5px;
    left: 5px;
    user-select: none;
    padding: 7px;
    font-family: var(--lbb-font-family-standard);
}

.hls-video-wrapper {
    min-width: 100%;
    min-height: 100%;
    transform: translate(0, -50%);
    top: 50%;
    position: absolute;
    overflow: hidden;
}

.hls-video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
       overflow: hidden
}