.lbb-drawer-toolbar {
    height: 35px;
    --min-height: 35px;
    padding-top: 0;
}

.lbb-drawer-header-buttons {
    --min-height: 35px!important;
    height:35px!important;
}

.lbb-drawer-menu-toggle {
    --min-height: 35px;
}

.lbb-drawer-toolbar ion-icon {
    color: #803ef3;
}

.lbb-drawer-toolbar ion-segment-button.segment-button-checked {
    color: #803ef3;
}

.lbb-drawer-content {
    background: white;
}