.event-info {
    color: white;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.33);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 10px;
}

@media only screen and (max-width: 995px) {
    .event-info {
       backdrop-filter: none;
    }
}

.event-info ion-grid {
    padding: 0;
    height: 100%;
}

.event-info ion-row {
    height: 100%;
}

.event-info ion-col {
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .event-info ion-text h1 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .event-info ion-text h1 {
        font-size: 18px;
    }
}


.event-info ion-text h3 {
    padding-right: 5px;
}

.event-info ion-text p {
    font-family: var(--lbb-font-family-standard);
    padding-right: 5px;
}

@media only screen and (max-width: 480px) {
    .event-info ion-text p {
        font-size: 15px;
    }
}

.event-info-avatar {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    --border-radius: 10px;
}

.event-info-content {
    display: flex;
}

.viewers-icon {
    display: none;
}

@media only screen and (max-width: 480px) {
    .rocket-tabs ion-label {
        font-size: 11px;
    }
    .viewers-text {
        display: none;
    }

    .viewers-icon {
        display: block;
    }
}
