.current-dancer-info {
    color: white;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.33);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 10px;
}

@media only screen and (max-width: 995px) {
    .current-dancer-info {
       backdrop-filter: none;
    }
}

.current-dancer-info-inner {
    display: flex;
    height: 100%;
    padding: 5px;
}

.current-dancer-info-content {
    display: flex;
    flex: 1;
}

.current-dancer-info .avatar-and-name-wrapper {
    display: flex;
}



.current-dancer-info ion-avatar {
    width: 60px;
    height: 60px;
    color: white;
    margin-right: 10px;
}

.current-dancer-info .friend-button {
    --background: var(--lbb-button-colour);
    cursor: 'pointer';
}

.current-dancer-info .friend-button.button-disabled {
    --background: none;
    --box-shadow: none;
    opacity: 1;
}
