.lbb-ranking-container {
    width: 100%;
    height: 100%;
    color: var(--lbb-title-color);

    background-color: rgba(255, 255, 255, 0.33);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 10px;
    overflow: auto;
    position: relative;
    
}

@media only screen and (max-width: 995px) {
    .lbb-ranking-container {
       backdrop-filter: none;
    }
}


#rankings .lbb-rankings-list {
    position: relative;
    width: 100%;
    background: transparent;
    font-family: var(--lbb-font-family-standard);
}

#rankings ion-avatar {
    margin-right: 10px;
}

/* #rankings::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/assets/champagne_bottle_transparent.svg);
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(45deg);
    opacity: 0.3;
} */



#rankings .lbb-list-item {
    --padding-end: 16px;
    --background: transparent
    /* --border-color: white; */
}

/* #rankings .lbb-avatar {
    padding: 10px;
} */

.lbb-champagne-icon {
    height: 2rem;
    width: 2rem;
    transform: rotate(45deg);
    color: white;
}



#rankings .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--lbb-main-color);
    height: 32px;

}

#rankings .title-wrapper h3 {
    margin: 0;
    font-size: 14px;

}


#rankings .lbb-title {
    margin-left: 10px;
    margin-right: 10px;
    color: var(--lbb-title-color);
}

#rankings .no-data {
    position: absolute;
    top: 50%;
    width: 100%;
    color: var(--lbb-title-color);
    font-family: var(--lbb-font-family-standard);
}

