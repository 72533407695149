.lbb-actor {
    width: 100%;
    height: 100%;

    .actor-video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        box-shadow: var(--lbb-shadow);
    }

    .guess-field {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 5px;
        width: 90%;
        margin: auto;
        --background: rgba(255,255,255,0.9);
    }

    .suggestions {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
    }
}

.react-select__option {
    color: red!important;
}
.react-select__menu-list {
    color: red!important;

}