.lbb-video-container {
    width: 100%;
    height: 100%;
    background-color: orangered;

    display: flex;
    align-items: center;
    justify-content: center;
}

