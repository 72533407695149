#party-people {
    height: 100%;
}

#party-people .party-people-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow-y: scroll;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none; 

}


#party-people .party-people-wrapper::-webkit-scrollbar {
    -ms-overflow-style: none;
    display: none;
} 

#party-people .party-people-avatar {
    position: relative;
    margin: 4px;
    --border-radius: 10px;
    width: 96px;
    height: 96px;
}

.party-people ion-searchbar {
    --background: rgba(255,255,255,0.3);
    --color: white;
    font-family: var(--lbb-font-family-standard);
    --border-radius: 10px;
}

.party-people ion-icon {
    color: white;
}