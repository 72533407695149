.editor-view-container {
    background-color: lightgray;
    position: absolute;
    top:100%;
    left: 0;
    min-width: 400px;

}



