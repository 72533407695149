#youtubevideo {
    width: 100%;
    height: 100%;
    background-color: #FF0000;

    display: flex;
    align-items: center;
    justify-content: center;
}

#youtubevideo:hover .btn-open-editor {
    visibility: visible;
    transition: all 0.3s ease;
}

#youtubevideo .btn-open-editor {
    position: absolute;
    left: 0;
    bottom: 0;
    visibility: hidden;
}