.lbb-scoreboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .score {
        position: relative;
        width: 40%;
        height: 0;
        padding-top: 40%;
        margin: 20px;
        background: aqua;

        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 33px;
        }
    }
}