@import url(https://fonts.googleapis.com/css2?family=Rowdies&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* ::-webkit-scrollbar {
    display: none;
}

:root {
    --rocket-gradient: linear-gradient(50deg, #1fd3ff, #803ef3, #ff4e7d), linear-gradient(140deg, #ff507e, #ffc2fa);
    --rocket-gradient-variant: linear-gradient(320deg, #1fd3ff, #803ef3, #ff4e7d), linear-gradient(30deg, #ff507e, #ffc2fa);
    --rocket-purple: #803ef3;
    /* --ion-background-color: transparent; */
    --ion-min-height:24px;
    --lbb-icon-color: white;
    --lbb-title-color: white; 
    --lbb-main-color: #803ef3;
    --lbb-accent: #ff4e7d;
    --ion-font-family: 'Rowdies', "Helvetica Neue", "Roboto", sans-serif;
    --lbb-font-family-standard: "Helvetica Neue", "Roboto", sans-serif;
    --lbb-button-colour: var(--lbb-main-color);
    --lbb-button-colour-variant: var(----ion-color-step-800);
}

@media (prefers-color-scheme: dark) {
    :root {
        color: rgba(0, 0, 0, 0.87);
        --color: rgba(0, 0, 0, 0.87);
    }
  }


html {
    --background: var(--rocket-gradient);
    --background: url(/images/rocket-background.jpg);
    --min-height: 24px;

    
}

body {
    -webkit-user-select: text;
    user-select: text;
   
}

p {
    font-family: var(--lbb-font-family-standard);
}




ion-header .toolbar-background {
    --background: transparent;
}

ion-content {
    /* --background: rgb(15, 76, 129); */
}

ion-button {
    --background: slategray;
    --background: var(--rocket-gradient);
    --color: white;
}

ion-item {
    --highlight-color-focused: darkslategray;
    --ion-color-secondary: darkslategray;
}


.grid-container {
    min-height: calc(100vh);
    text-align: center;
    padding-top: 35px;
}

.rocket-background {
    background: var(--rocket-gradient);
    background: url(/images/rocket-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@media only screen and (max-width: 995px) {
    .rocket-background {
        background: var(--rocket-gradient);
    }
}

.friends-tab {
    position: absolute;
    left: 0;
}    

#friends ion-button {
    --background: white;
}

.toolbar-background {
    --background: transparent!important;
}


ion-toolbar::part(placeholder) {
    --background: green;
}

.lbb-header-buttons {
    --min-height: 36px!important;
    height:36px!important;
}

.lbb-header-button {
    min-height: 35px;
    height: 35px!important;
    width: 35px!important;
    --padding-top: 0!important;
    --padding-end: 0!important;
    --padding-bottom: 0!important;
    --padding-start: 0!important;
    margin: 0 16px!important;
    color: var(--lbb-icon-color);
}

.lbb-header-icon {
    width: 32px;
    height: 32px;
}

.lbb-top-right-fab {
    margin-top: 20px;
}

.lbb-fab-button {
    --background: transparent;
    width: 60px;
    height: 60px;

}

.lbb-fab-icon {
    width: 50px;
    height: 50px;
}

.lbb-fab-main-button {
    --background: #803EF3;
}

