.is-online-badge {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    /* transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%; */
    color: var(--ion-color-success);
    background: var(--ion-color-success);
    border-radius: 50%;
}

.is-online-badge.large-badge {
    width: 20px;
    height: 20px;
}

.is-online-badge.is-header {
    position: relative;
    display: block;
    margin-right: 7px;
    margin-top: 5px;
}


.is-online-badge::after {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 8px;
    width: 8px;
    border: 1px solid var(--ion-color-success);
    height: 8px;
    content: "";
    /* animation: onlineDot 1.2s infinite ease-in-out; */
    border-radius: 50%;
}

.is-online-badge.large-badge::after {
    width: 20px;
    height: 20px;
}

.is-online-badge.has-no-ripple::after {
    animation: none;
}

@keyframes onlineDot {
    0% {
        transform: scale(.8);
        opacity: 1;
    }
    100% {
        transform: scale(2.4);
        opacity: 0;
    }
}
