.settings-dashboard {
    --background: rgba(27, 27, 27, 1);
    color: white;
    --width: 95vw;
    --height: 95vh;

    ion-toolbar {
        --background: transparent;
        --min-height: 48px;
        color: white;
        font-family: var(--lbb-font-family-standard);
        font-size: 20px;
        text-transform: uppercase;
    }

    ion-content {
        --background: transparent;
    }

    .RRT__container {
        display: flex;
        max-width: 900px;
        // background: aqua;
        margin: auto;
        color: white;
        font-family: var(--lbb-font-family-standard);
        height: 100%;

        .RRT__tabs {
            width: 200px;
            display: flex;
            flex-direction: column;

            .RRT__tab {
                padding: 10px;
                background: rgba(27,27,27,0.95);
                margin-bottom: 1px;
                border: none;
                height: 40px;

                &:hover {
                    background: rgba(21,21,21,0.95,);
                }

                &.RRT__tab--selected {
                    background: rgba(24,24,24,0.95);
                }
            }
        }

        .RRT__accordion {
            width: 100%;
        }

        .RRT__panel {
            width: 100%;
            border: none;
            margin-left: 1px;
            background: rgba(24,24,24,0.95);
            padding: 16px;

            .dashboard-highlight {
                background: rgba(31,31,31,1);
                border: 1px solid #383838;
                border-radius: 5px;
                padding: 16px;

                ion-col {
                    display: flex;
                    flex: 1;
                    align-items: center;
                }
            }

            .change-button {
                vertical-align: middle;
                text-align: center;
            }


            .board-title {
                height: 36px;
                margin-bottom: 8px;
                text-transform: uppercase;
            }

            .board-title ion-col {
                padding: 0 20px;
            }

            .board-title ion-col h2 {
                margin-top: 0;
            }

            .profile-avatar {
                width: 80px;
                border-radius: 5px;

                img {
                    border-radius: 5px;
                    margin: 0;
                }
            }

            .dashboard-button {
                --background: slategray;
                margin-left: auto;
                font-family: var(--lbb-font-family-standard);
                font-weight: 700;
            }

            .display-name-wrapper {
                margin-left: 16px;
                margin-right: 16px;
                flex-grow: 1;
            }

            ion-item {
                --background: transparent;
                color: white;
                // --color-focused: red;
                // --color-activated: red;
                --color-activated: red;
                --highlight-color-focused: red!important;
            }

            ion-label {
                color: slategray;
                text-transform: uppercase;
                font-family: var(--lbb-font-family-standard);
                font-weight: 700;
            }

            ion-input {
                font-family: var(--lbb-font-family-standard);
            }
        }
    }

}