
.countdown-container {
    position: absolute;
    height: auto;
    width: auto;
    top: 15px;
    right: 15px;
    display: flex;    
}


.countdown-container .digit {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    /*background: ${(props) => props.flipGradient ? "linear-gradient(210deg, #ff41f8 5%, #71cdff 90%)" : "linear-gradient(30deg, #ff41f8 5%, #71cdff 90%)"};
    */
    color: white;
    color: #3c3d3d;
    background: rgba(244,245,248,0.8);
    font-size: 24px;
    font-family: 'Rowdies', cursive;
    font-weight: 300;
    margin-left: 5px;
    box-shadow: 0 0 6px 0 rgba(0,0,0, 0.4);
    position: relative;
    transition: all 1s;
    transition-delay: 2.0s;
    
    transform: scale(1);
    opacity: 1;

}

.countdown-container .here-we-go {
    transform: scale(0);
    opacity: 0;
}


.countdown-container .digit span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: transform 0.5s;
    transition-delay: 0.5s;
}
