.rocket-tabs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

}

.rocket-tabs .rocket-tabs-button {
    background: var(--lbb-main-color);
    color: white;
    border: none;
    min-height: 32px;
    height: 32px;
}

.rocket-tab ion-segment {
    padding-left: 7px;
    padding-right: 2px;
}

.rocket-tabs ion-segment-button {
    margin-right: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rocket-tabs ion-segment-button:first-of-type {
    margin-left: 7px;
}

.rocket-tabs ion-segment-button:last-of-type {
    margin-right: 7px;
}

.rocket-tabs ion-segment-button.segment-button-checked {
    --color: white;
    --indicator-color: white;
    --color-checked: var(--color-hover);
    --indicator-color: var(--ion-toolbar-segment-color, rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6));
    --indicator-color: white;
    --indicator-height: 2px!important;
    /* --background-checked: #803ef3; */
}


.rocket-tabs ion-segment-button.segment-button-checked::after {
    background-color: #6d6d6d;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23ffffff' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
}

.rocket-tabs ion-toolbar {
    background: transparent;
    --background: transparent;
    min-height: 32px;
    --min-height: 32px;
}

.rocket-tabs ion-list {
    background: transparent;
}

.rocket-tabs ion-item {
    --background: transparent;
    --min-height: 32px;
}

.rocket-tabs .rocket-tabs-content {
    
    background-color: rgba(255, 255, 255, 0.33);
    backdrop-filter: saturate(180%) blur(20px);
    border-radius: 10px;
    height: 100%;
    height: calc(100% - 32px);
    width: 100%;
}

@media only screen and (max-width: 995px) {
    .rocket-tabs .rocket-tabs-content {
       backdrop-filter: none;
    }
}

.rocket-tabs .lbb-chat {
    /* height: calc(100% - 32px); */
}

.rocket-tabs .lbb-chat ion-text {
    min-height: 20px;
}

.rocket-tabs .lbb-chat .chat-content {
    margin-bottom: 0;
}

.rocket-tabs .lbb-chat .chat-text-area {
    --padding-top: 7px;
    --padding-bottom: 7px;
}

.rocket-tabs .lbb-chat ion-textarea {
    margin: 0;
}

.rocket-tabs ion-label {
    position: relative;
    white-space: pre-wrap;
    line-height: 1;
}

@media only screen and (max-width: 480px) {
    .rocket-tabs ion-label {
        font-size: 11px;
    }
}