.change-display-name-wrapper {
   
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    max-height: 38px;
    min-width: 340px;
}

.change-display-name {
    font-size: 85%;
    text-align: center;
    font-family: var(--lbb-font-family-standard);
    border: 1px solid var(--lbb-main-color);
}

ion-input.change-display-name {
    /* border: 1px solid black; */
    max-width: 100%;
    margin: 0 auto;
    background: #f7f7f7;
    border: 1px solid var(--lbb-main-color);
    border-radius: var(--border-radius);
   
}

ion-input.change-display-name.has-focus {
    background: white;
    
}

ion-input.change-display-name .native-input.sc-ion-input-md {
    color: var(--lbb-main-color);
    --placeholder-opacity: 0.8;
}

.profile-modal ion-button {
    --background: var(--lbb-button-colour);
}

.join-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

ion-button.join-button {
    text-transform: none;
    font-family: var(--lbb-font-family-standard);
    min-width: 340px;
    max-width: 100%;
    --background: white;

    color: var(--lbb-main-color);
    transition: all 0.3s ease;
    --border-color: var(--lbb-main-color);
}

ion-button.join-button.join-button-solid {
    --background: var(--lbb-main-color);

    color: white;
}

span.display-name {
    margin-left: 1ch;
    font-weight: 700;
}