.lbb-friends-container {
    width: 100%;
    height: 100%;
}

.friends-avatar {
    --border-radius: 10px;
    position: relative;
}

.lbb-friends-container ion-segment-button.friends-segment-button {
    border-top-right-radius:10px;
    border-top-left-radius: 10px;
    border-right: 1px solid white;
    margin-top: 1px;
    background: var(--lbb-main-color);
    color: white;
    min-height: 32px;
    height: 32px;
}

.lbb-friends-container ion-segment-button.friends-segment-button.segment-button-checked {
    --color: white;
    --indicator-color:  var(--ion-toolbar-segment-color, rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6));
    --indicator-height: 2px;
    --color-checked: white;
}

.lbb-friends-container ion-segment-button.friends-segment-button.segment-button-checked:hover {
    --color: var(--color-hover);
}

.lbb-friends-content-wrapper {
    height: inherit;
    overflow: scroll;
}
