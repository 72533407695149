.webrtc-queue-progress-bar-container {
	position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: initial;
}

.webrtc-queue-progress-bar {
	height: 10px!important;
    top: calc(100% - 10px)!important;
    background: transparent;
}

.webrtc-queue-progress-bar-color {
    --progress-background: linear-gradient(50deg, #1fd3ff, #803ef3, #ff4e7d), linear-gradient(140deg, #ff507e, #ffc2fa);
    --buffer-background: transparent!important;
}

ion-progress-bar .progress-buffer-bar {
    background: transparent!important;
    --buffer-background: transparent;
}