.login-policy-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.text-decoration-none {
    text-decoration: none;
}

.login-page ion-button {
    --background: var(--lbb-button-colour);
}