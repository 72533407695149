.main-header {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.33);
    /* backdrop-filter: saturate(180%) blur(20px); */
    backdrop-filter: none;
}

@media only screen and (max-width: 995px) {
    .main-header {
       backdrop-filter: none;
    }
}

.main-header ion-toolbar:first-of-type {
    /* background: transparent; */
    height: 35px;
    min-height: 35px;
    padding-top: 0;
}

#main-header::after {
    display: none;
}

.toolbar-background {
    min-height: 24px;
    height: 24px;
    --background: transparent;
}

.toolbar-container {
    min-height: 24px!important;
    height: 24px!important;
    max-height: 24px!important;
    --min-height: 24px!important;

}

.main-header .profile-wrapper {
    position: relative;
}


.lbb-top-toolbar {
    --background: transparent;
    --min-height: 30px!important;
}

.lbb-header-buttons {
    /* flex: 1; */
}

#main-header ion-title {
    font-family: var(--ion-font-family);
    color: white;
    flex: 1;
    margin-top: -1px;
}

#main-header ion-badge {
    padding: 7px;
    font-family: var(--lbb-font-family-standard);
}


.opacityzero {
    opacity: 0; 
}

sup {
    font-family: var(--lbb-font-family-standard);
    font-size: 50%;
    top: -1em;
} 