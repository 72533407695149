.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.video-box {
  background-color: wheat;
}

.chat-module {
  background-color: turquoise;
}

.rankings-module {
  background-color: orangered;
}
